import React, { PureComponent, Fragment } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import SpecialBlock from '../conponents/shared/SpecialBlock.jsx';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form'; 
import Textinput, { regexCheck } from '../conponents/shared/Textinput';
import Textarea from '../conponents/shared/Textarea';
import Radioinput from '../conponents/shared/Radioinput';
import Dropdown from '../conponents/shared/Dropdown';
import { PRIVATE_EVENT } from '../shared/form';
import APPCONFIG from '../config/config.js';
import * as indexAction from '../actions/index';

import privateEvent01 from '../static/private-event01.jpg';
import privateEvent02 from '../static/private-event02.jpg';
import privateEvent03 from '../static/private-event03.jpg';
import { stat } from 'fs';

// buildurl
// const StyleTextInner = styled.div`
//     font-weight: 400;
//     font-size: 1.3rem;
//     line-height: 1.8rem;
//     text-align: ${props => props.LangStyle ? 'justify' : 'start'};
//     text-align-last: ${props => props.LangStyle ? 'center' : 'start'};
//     letter-spacing: ${props => props.LangStyle ? '1px' : '0px'};
//     @media (max-width: 1400px) {
//         font-size: ${props => props.LangStyle ? '1.2rem' : '1.2rem'};
//         line-height: ${props => props.LangStyle ? '1.5rem' : '1.5rem'};
//     }
//     @media (max-width: 414px) {
//         width:95%;
//         margin: 0 auto;
//         font-size: ${props => props.LangStyle ? '.9rem' : '1rem'};
//         line-height: ${props => props.LangStyle ? '1.4rem' : '1.3rem'};
//     }
// `;

// buildurl jp
const StyleTextInner = styled.div`
    font-family: 'MS PGothic';
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 1.8rem;
    text-align: ${props => props.LangStyle ? 'justify' : 'start'};
    text-align-last: ${props => props.LangStyle ? 'center' : 'start'};
    letter-spacing: ${props => props.LangStyle ? '1px' : '0px'};
    @media (max-width: 1400px) {
        font-size: ${props => props.LangStyle ? '1.2rem' : '1.2rem'};
        line-height: ${props => props.LangStyle ? '1.5rem' : '1.5rem'};
    }
    @media (max-width: 414px) {
        width:95%;
        margin: 0 auto;
        font-size: ${props => props.LangStyle ? '.9rem' : '1rem'};
        line-height: ${props => props.LangStyle ? '1.4rem' : '1.3rem'};
    }
`;

const StyleContentBg = styled.div`
    width: 100%;
    background: linear-gradient(to bottom, #f3f3eb 35%,#e8e6da 35%,#e8e6da 100%);
    padding-bottom: 10px;
    @media (max-width: 1023px) {
        background: none;
    }
`;

const StyleContent = styled.div`
    display: flex;
    width: 1160px;
    margin: 90px auto;
    justify-content: space-between;
    flex-flow: wrap;
    @media (max-width: 1366px) {
        width: 90%;
        margin: 90px auto 0;
      }
      @media (max-width: 414px) {
        margin: 30px auto 0;
      }
`;

const StyleServiceItem = styled.div`
    display: flex;
    width: 30%;
    flex-direction: column;
    color: #868686;
    @media (max-width: 1023px) {
        width: 100%;
        margin: 35px 0;
      }
      @media (max-width: 414px) {
        margin: 15px 0;
      }
`;

const StyleServiceImg = styled.div`
    width: 100%;
    height: 300px;
    margin-bottom: 50px;
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: 1023px) {
        height: 350px;
    }
    @media (max-width: 414px) {
        margin-bottom: 30px;
    }
`;

// buildurl
// const StyleTextTitle = styled.div`
//     font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
//     display: block;
//     color: #5d5d5d;
//     text-transform: uppercase;
//     text-decoration: none;
//     font-size: 1.8rem;
//     font-weight: 700;
//     letter-spacing: -1px;
//     margin-bottom: .5em;
//     text-align: center;
//     @media (max-width: 1366px) {
//         font-size: 1.5rem;
//         margin-bottom: 5px;
//     }
//     @media (max-width: 414px) {
//         font-size: 1.2rem;
//         margin-bottom: 5px;
//     }
// `;

// buildurl jp
const StyleTextTitle = styled.div`
    font-family: 'MS PGothic', 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
    display: block;
    color: #5d5d5d;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 1.8rem;
    font-weight: 700;
    letter-spacing: -1px;
    margin-bottom: .5em;
    text-align: center;
    @media (max-width: 1366px) {
        font-size: 1.5rem;
        margin-bottom: 5px;
    }
    @media (max-width: 414px) {
        font-size: 1.2rem;
        margin-bottom: 5px;
    }
`;

class PrivateEvents extends PureComponent<Props> {

    constructor(props) {
        super(props);
        this.state = {
            isMan: true,
            errorMsg: {
                name: '',
                phone: '',
                email: '',
            },
        };
        this.setHintMsg = this.setHintMsg.bind(this);
    }

    componentWillMount() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    componentDidUpdate(preprops) {
        const {
            location: {
                pathname,
                state,
              },
            fetchNodeData,
            menuData,
            nodeData,
            allNodes,
            footerData,
            sendEmailData,
            sendEmailSuccess,
        } = this.props;
        const mainpPath = pathname.split("/");
        const contactUs = footerData.find(val => val.route === '/contact-us');
        if(state) {
            if(!nodeData['/contact-us']) {
                if(nodeData[`/${mainpPath[2]}`] && nodeData['/reservations']) {
                    if (contactUs) {
                        fetchNodeData(contactUs.url, contactUs.route);
                    }
                } 
            }
        } else {
            if(footerData.length && menuData.length) {
                if(nodeData[`/${mainpPath[2]}`] && nodeData['/reservations'] && !nodeData['/contact-us']) {
                    fetchNodeData(contactUs.url, contactUs.route);
                }  
            } 
        } 
        if(sendEmailData && sendEmailData.statusCode === 0) {
            this.props.reset(PRIVATE_EVENT);
            sendEmailSuccess(null);
        } 
    }

    postData() {
        const {
            LastName,
            FirstName,
            Phone,
            Email,
            Stores,
            Opinion,
            sendEmail,
            nodeData,
            Sex,
            langData,
        } = this.props;
        const lastNameMsg = regexCheck('name', LastName || '');
        const firstNameMsg = regexCheck('name', FirstName || '');
        const phoneMsg = regexCheck('phone', Phone || '');
        const emailMsg = regexCheck('email', Email || '');
        const storeMail = nodeData['/reservations'].datas.find(val => val.title === Stores) || nodeData['/reservations'].datas[0];
        const storeName = Stores || storeMail.title;
        if (lastNameMsg || firstNameMsg || phoneMsg || emailMsg) {
            this.setState({
                errorMsg: {
                    lastName: lastNameMsg,
                    firstName: firstNameMsg,
                    phone: phoneMsg,
                    email: emailMsg,
                },
            })
        } else {
            const post = {
                email: Email,
                store: {
                    name: storeName,
                    email: storeMail.subHtml,
                  },
                comments: Opinion,
                name: LastName + ' ' + FirstName,
                phone: Phone,
                sex: '',
                head: langData.emailHead['privateevents'],
            };
            sendEmail(post);
        }
    }

    setHintMsg(name, value) {
        const {
            errorMsg,
        } = this.state;
        this.setState({
            errorMsg: {
                ...errorMsg,
                [name]: value,
            },
        });
    }


    render() {
        const {
            nodeData,
            langData,
            location: {
                state,
                pathname,
            },
            sendEmailData,
        } = this.props;
        const mainpPath = pathname.split("/");
        if (!nodeData[`/${mainpPath[2]}`]) return null;
        const responseData = nodeData[`/${mainpPath[2]}`];
        const data = nodeData['/reservations'] && nodeData['/reservations'].datas;
        const contactData = nodeData['/contact-us'] && nodeData['/contact-us'];
        const contactPlaceholder = contactData && contactData.datas[1].datas;
        if (!contactPlaceholder) return null;
        const {
            errorMsg,
        } = this.state;
        return (
            <div class="content-warpper">
                <div class="private-events-title-warpper">
                    <div class="content-title">{responseData.title}</div>
                    <div class={langData.type === "zh-TW" ? 'content-sub-title-inner private-events-title' : 'en-content-sub-title-inner'}>{responseData.summary}</div>                    
                </div>

                <StyleContentBg>
                    <StyleContent>
                        {responseData.datas.map(val => 
                        <Fragment>
                            <StyleServiceItem>
                                <StyleServiceImg style={{backgroundImage: `url('${val.url}')`}} />
                                <StyleTextTitle>
                                    {val.title}
                                </StyleTextTitle>
                                <StyleTextInner LangStyle={langData.type === "zh-TW"}>
                                    {val.summary}
                                </StyleTextInner>
                            </StyleServiceItem>
                            <div class="private-block-style"></div>
                        </Fragment>
                        )}
                    </StyleContent>
                </StyleContentBg>

                <div class="content-sub-warpper">
                <div class="menber-title" style={{fontFamily: process.env.COUNTRY === "358D7623AB3849109CBC39652EEBB70F" ? 'MS PGothic' : ''}}>{responseData.html}</div>
                    <div class="content-input-warpper">
                        <Field
                            regex="name"
                            placeholder={contactPlaceholder[0].title}
                            name="LastName"
                            hintMsg={errorMsg.lastName}
                            setHintMsg={this.setHintMsg}
                            component={Textinput} />
                        <Field
                            regex="name"
                            placeholder={contactPlaceholder[10].title}
                            name="FirstName"
                            hintMsg={errorMsg.firstName}
                            setHintMsg={this.setHintMsg}
                            component={Textinput} />
                        <Field
                            regex="phone"
                            placeholder={contactPlaceholder[3].title}
                            name="Phone"
                            setHintMsg={this.setHintMsg}
                            hintMsg={errorMsg.phone}
                            component={Textinput} />
                        <Field
                            regex="email"
                            placeholder={contactPlaceholder[4].title}
                            name="Email"
                            setHintMsg={this.setHintMsg}
                            hintMsg={errorMsg.email}
                            component={Textinput} />
                        <Field
                            isFullWidth
                            placeholder={contactPlaceholder[6].title}
                            name="Stores"
                            data={data}
                            optiontype="store"
                            component={Dropdown} />
                        <Field
                            placeholder={contactPlaceholder[8].title}
                            name="Opinion"
                            component={Textarea} />
                    </div>
                    <div class="content-sub-innner-warp">
                        <div class="content-gray-bt" onClick={() => this.postData()}>{contactPlaceholder[9].title}</div>
                    </div>  
                </div>

                <SpecialBlock pathname={pathname} temp={1} />

            </div>
        );
    }
}


const selector = formValueSelector(PRIVATE_EVENT);

const reduxHook = connect(
    state => ({
        LastName: selector(state, 'LastName'),
        FirstName: selector(state, 'FirstName'),
        Phone: selector(state, 'Phone'),
        Email: selector(state, 'Email'),
        Stores: selector(state, 'Stores'),
        Opinion: selector(state, 'Opinion'),
        Sex: selector(state, 'Sex'),
        nodeData: state.Content.nodeData,
        footerData: state.Content.footerData,
        menuData: state.Content.menuData,
        allNodes: state.Content.allNodes,
        langData: state.Content.lang,
        sendEmailData: state.Content.sendEmailData,
    }),
    dispatch => bindActionCreators({
        ...indexAction,
    }, dispatch)
);

const formHook = reduxForm({
    form: PRIVATE_EVENT,
    initialValues: {
        LastName: '',
        FirstName: '',
        Phone: '',
        Sex: 'Male',
        Email: '',
        Stores: '',
        Opinion: '',
      },
});

export default compose(
    reduxHook,
    formHook,
)(PrivateEvents);